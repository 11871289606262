<template>
    <div>
        {{ minutes() }}:{{ seconds() }}
    </div>
</template>

<script>
export default {
    name: 'TimerComponent',
    props: ["timer", "timerActive"],
    methods: {
        minutes() {
            if (this.timer < 600) {
                return ('0' + (Math.floor(this.timer / 60)).toFixed()).slice(-2);
            } else {
                return Math.floor(this.timer / 60).toFixed();
            }
        },
        seconds() {
            return ('0' + (this.timer % 60).toFixed()).slice(-2);
        }
    }
}
</script>